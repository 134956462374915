<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <Confirm ref="confirm" :method="store"/>
      <transition name="slide">
        <form ref="frmNuevo" novalidate>
          <CCard class="border-warning">
            <CCardHeader>
              <strong class="text-primary">
                Editar Facultad
              </strong>
              <div class="card-header-actions">
                <button @click="$refs.modalVideo.mostrarVideo('video-alumno-nuevo.mp477777777777777777')"
                        class="btn btn-outline-info" title="Ver video de ayuda">
                  <font-awesome-icon icon="fas fa-question-circle" />
                  Ayuda
                </button>
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput label="Código" type="number" min="1" max="999999999" v-model="facultad.codigo" required="required"
                          @change="facultad.codigo=facultad.codigo.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput label="Sigla" type="text" v-model="facultad.sigla"
                          @change="facultad.sigla=facultad.sigla.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-6">
                  <CInput label="Descripción (Nombre de la facultad)" type="text" v-model="facultad.descripcion" required="required"
                          @change="facultad.descripcion=facultad.descripcion.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-4">
                  <CInput label="Dirección" type="text" v-model="facultad.direccion" required="required"
                          @change="facultad.direccion=facultad.direccion.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-4">
                  <label>Estado (Y = habilitado, N = deshabilitado)</label>
                  <select v-model="facultad.estado" required="required" class="form-control   ">
                    <option value="Y">HABILITADO</option>
                    <option value="N">DESHABILITADO</option>
                  </select>
                </div>
              </div>
            </CCardBody>
            <CCardFooter>
              <CButton class="mr-2" color="primary" @click="store()">Grabar</CButton>
              <CButton color="danger" @click="goBack">Regresar a la pantalla anterior</CButton>
            </CCardFooter>
          </CCard>
        </form>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalVideo from "@/views/notifications/ModalVideo";
import Confirm from "@/views/notifications/Confirm";

var moment = require('moment');
var modalPdf = null;

export default {
  name: 'EditUser',
  components: {Confirm, ModalVideo, Alerts, Toast},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      facultad: {
        id: 0,
        sede_id: localStorage.sedeSeleccionada,
        codigo:0,
        sigla:'',
        descripcion:'',
        direccion:'',
        estado:'Y',
      },
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    store() {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
      } else {
        modalPdf.$refs.alert.show('Descargando documento');
        axios.post(this.$apiAdress + '/api/facultad/store?token=' + localStorage.getItem("api_token"),
            modalPdf.facultad
        )
            .then(function (response) {
              modalPdf.$refs.alert.hide();
              modalPdf.facultad = {
                id: 0,
                sede_id: localStorage.sedeSeleccionada,
                codigo:0,
                sigla:'',
                descripcion:'',
                direccion:'',
                estado:'Y',
              };
              modalPdf.$refs.mensajeToast.makeToast('', 'Registro creado satisfactoriamente.', 'success');
              modalPdf.$router.push({path: '/facultad/'});
            })
            .catch(function (error) {
              modalPdf.$refs.alert.hide();
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
  },
  computed: {
  },
  mounted: function () {
    modalPdf = this;
    modalPdf.facultad.sede_id = localStorage.sedeSeleccionada;
    axios.get(this.$apiAdress + '/api/facultad/' + modalPdf.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.facultad = response.data.facultad;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}

</script>
<style>